import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify'
import { withRouter } from 'react-router-dom'

import { ConfirmationProvider } from 'components/confirm'
import Main from 'screens'

import MenuContext from './contexts/Menu'
import ErrorBoundary from './contexts/ErrorBoundary'
import { AmplitudeProvider } from './contexts/Amplitude'

import 'react-toastify/dist/ReactToastify.css'

class App extends Component {
  state = {
    leftNavOpen: false
  }

  closeLeftMenu = () => {
    this.setState({
      leftNavOpen: false
    })
  }

  componentDidMount() {
    document.addEventListener('DOMNodeInserted', function () {

    const elements = document.querySelectorAll('[aria-owns]');

      elements.forEach(element => {
        element.removeAttribute('aria-owns');
      });
    })
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname }
    } = this.props
    const previousLocation = prevProps.location.pathname
    if (pathname !== previousLocation) {
      if (window && window.userpilot && window.userpilot.reload) {
        window.userpilot.reload()
      }
    }
  }

  render() {
    return (
      <div>
        <ErrorBoundary>
          <ConfirmationProvider>
            <MenuContext.Provider
              value={{
                isOpen: this.state.leftNavOpen,
                closeLeftMenu: this.closeLeftMenu
              }}
            >
              <AmplitudeProvider>
                <Main />
              </AmplitudeProvider>
            </MenuContext.Provider>
          </ConfirmationProvider>
          <ToastContainer hideProgressBar={true} style={{ zIndex: 10000 }} />
        </ErrorBoundary>
      </div>
    )
  }
}

export default withRouter(App)
