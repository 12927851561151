import _ from 'lodash'
import TaskTemplates from 'data/TaskTemplates.json'

const getBindableTasks = areas => {
  let areaTypes
  let areaGroups
  let results = []

  areaTypes = _.uniq(areas.map(area => area.type))
  areaGroups = _.groupBy(areas, 'type')

  areaTypes.forEach(areaType => {
    if (TaskTemplates[areaType]) {
      const data = TaskTemplates[areaType]
      let name = data.sectionName
      if (areaGroups[areaType] && areaGroups[areaType].length === 1) {
        name = areaGroups[areaType][0].name
      }

      results.push({
        name,
        tasks: data.tasks
      })
    }
  })

  return results
}

export const getLocationTemplateTasks = ({existingAreas, locationType, locationTemplates}) => {
  let areas = existingAreas || []
  if (
    (!areas || !areas.length) &&
    locationTemplates?.length
  ) {
    let location = _.find(locationTemplates, { name: locationType })
    areas = location?.areas
  }

  if (areas?.length > 0) {
    let tasks = getBindableTasks(areas)

    if (tasks && tasks.length) {
      return tasks
    }
  } else {
    return null
  }
}