import { createContext, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { store } from 'store';

const getUserState = () => {
    const userStore = store.getState()?.user
    return userStore
}




const AmplitudeContext = createContext()

const AmplitudeProvider = ({ children }) => {

    const userState = getUserState()

    const auth0ID = !userState.employment ? '' : userState.employment[0].auth0_id

    if (auth0ID === '') return (<>{children}</>)

    const deviceID = useMemo(() => {

        return Date.now().toString()

    }, [auth0ID])

    const initAmplitude = useCallback(() => {

        if (!auth0ID) return;

        const sesionReplayTrack = sessionReplayPlugin({
            sampleRate: 0.5
        })

        const instance = amplitude.createInstance()
        instance.setDeviceId(deviceID)
        instance.setUserId(auth0ID)
        instance.set
        instance.add(sesionReplayTrack)
        instance.init(process.env.REACT_APP_AMPLITUDE_API_KEY)

    }, [auth0ID])


    useEffect(() => {

        initAmplitude();

        return () => {
            amplitude.remove('sessionReplayTracking');
        };

    }, [initAmplitude])

    return <AmplitudeContext.Provider value={{}}>{children}</AmplitudeContext.Provider>

}

export { AmplitudeProvider }
export default AmplitudeContext