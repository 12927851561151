const legal = `- CONTRACTOR acknowledges that CONTRACTOR will delegate all Services to be performed as an Independent Contractor.

- Included in the Service Charge will be service, supplies, and any equipment which will be furnished by CONTRACTOR. The Service Charge does not include any use tax, tax on sales, services or supplies, or other such tax, which taxes shall be paid by CUSTOMER. CUSTOMER agrees to reimburse CONTRACTOR the amount of any such taxes if paid by CONTRACTOR, on CUSTOMER’s behalf.

- All Services specified in the Task Schedule section of this Proposal will be provided to CUSTOMER in a satisfactory manner. CUSTOMER acknowledges that only those Services and/or Additional Services specifically identified in the Scope of Work will be provided under this Service Agreement.

- CONTRACTOR maintains and carries the required General Liability and Umbrella Insurance.

- The Service Charge will remain in effect for one year unless there are changes in the original specifications for the premises. In the event of such changes, CUSTOMER will advise CONTRACTOR accordingly, and an adjustment in the Service Charge, as agreed to by the parties, will be made.

- Services shall be performed as stated in the Task Schedule section of this Proposal except for the following six (6) legal holidays: New Year’s Day, Memorial Day, Independence Day, Labor Day, Thanksgiving Day and Christmas Day. No Service Charge credits will be issued for these holidays. However, service can be provided on these holidays at an additional cost if required. Services shall be scheduled during the hours approved or directed by manager/owner.

- The undersigned warrant and represent that they have full authority to enter into this Service Agreement, and that it will be binding upon the parties and their respective successors and assigns.

- Any information, suggestions, or ideas transmitted by CUSTOMER to CONTRACTOR in connection with products ordered by CUSTOMER are not to be regarded as secret or submitted in confidence except as may be otherwise provided in writing signed by an officer of the Company.

- All Parties contributed to the drafting of the Agreement and no presumption for or against any party to this Agreement shall arise as a result of the drafting. Nothing contained in this Agreement shall be construed to require the commission of any act contrary to law, and wherever there is any conflict between any provision of this Agreement and any present or future statute, law, governmental regulation or ordinance contrary to which the Parties have no legal right to contract, the latter shall prevail, but in such event, the affected provisions of this Agreement shall be curtailed and restricted only to the extent necessary to bring them within legal requirements.

- This Service Agreement constitutes the complete agreement of the parties concerning the provision of services to the CUSTOMER and supersedes all other prior or contemporaneous agreements between the parties, whether written or oral, on the same subject. No waiver or modification of this Service Agreement shall be valid unless

- This Agreement may be executed in counterparts and via facsimile or PDF, and may be distributed via email, none of which shall affect the validity or enforceability of this agreement.

- CUSTOMER to be billed Bi-weekly and CUSTOMER agrees to pay CONTRACTOR the amount that is due and owing under the terms of this Service Agreement within 15 days of billing date. Late payments may accrue finance charges as referenced herein. CONTRACTOR can receive payment in the form of a check, ACH or credit card on file.

- Term of the Service Agreement is for one year. The one-year period shall begin on the date services are scheduled to begin. CUSTOMER can cancel with a 30-day written notice if they are unsatisfied with their services. CUSTOMER must agree to give CONTRACTOR ample time to attempt to rectify any complaints or concerns with service.

- Service Contract will automatically renew if CUSTOMER does not notify CONTRACTOR of any revisions. In return if anything changes with agreement, the CONTRACTOR will notify the CUSTOMER 

- CONTRACTOR reserves the right to charge a monthly service charge equal to the greatest amount allowed by applicable law, or as agreed upon by CONTRACTOR and CUSTOMER, payable on all overdue balances.

- Labor rate is based upon current minimum wage rates and are subject to change state by state. If minimum wage increases, then there will be an equal percentage increase to contract service agreement upon renewal date.`

export default legal